import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  inject,
  Input,
  output,
  ViewChild,
  type TemplateRef,
} from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TextboxSize, TextboxType } from './textbox.types';

@Component({
  selector: 'app-textbox',
  styleUrls: ['./textbox.component.scss'],
  templateUrl: './textbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextboxComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgTemplateOutlet],
})
export class TextboxComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() description = '';
  @Input() errorMessage: string | TemplateRef<unknown> = '';
  @Input() size: TextboxSize = TextboxSize.Default;
  @Input() cssClass: string;
  @Input() icon = '';
  @Input() type: TextboxType = TextboxType.Text;
  @Input() value = '';
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() required = false;
  @Input() width = '280px';
  @Input() showClearIcon = false;
  @Input() autocomplete = '';

  @ViewChild('nativeInput', { read: ElementRef }) nativeInput: ElementRef<HTMLInputElement>;
  @ViewChild('nativeTextarea', { read: ElementRef }) nativeTextarea: ElementRef<HTMLTextAreaElement>;

  clearIconClick = output<void>();

  previewPassword = false;

  protected readonly TextboxSize = TextboxSize;
  protected readonly TextboxType = TextboxType;

  private readonly cdr = inject(ChangeDetectorRef);

  get clearIconVisible(): boolean {
    if (this.showPasswordToggle) return false;
    return this.showClearIcon;
  }

  get showPasswordToggle(): boolean {
    return this.type === TextboxType.Password && !!this.value;
  }

  get showError(): boolean {
    return !!this.errorMessage;
  }

  get customErrorTemplate(): TemplateRef<unknown> | undefined {
    if (typeof this.errorMessage === 'string') return undefined;
    return this.errorMessage as TemplateRef<unknown>;
  }

  get _type(): string {
    return this.previewPassword && this.type === TextboxType.Password ? TextboxType.Text : this.type;
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  showPasswordPreview(): void {
    this.previewPassword = true;
  }

  hidePasswordPreview(): void {
    this.previewPassword = false;
  }

  focus(): void {
    setTimeout(() => {
      if (this.type === TextboxType.Textarea) {
        this.nativeTextarea.nativeElement.focus();
      } else {
        this.nativeInput.nativeElement.focus();
      }
    }, 0);
  }
}
