export enum TextboxSize {
  Default = 'default',
  Large = 'large',
}

export enum TextboxType {
  Text = 'text',
  Password = 'password',
  Email = 'email',
  Number = 'number',
  Textarea = 'textarea',
  Phone = 'phone',
  Url = 'url',
}
